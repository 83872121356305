import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Card, Button, Modal, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { get_all_lots } from "../../Actions/SuperAdminAction/actions";
import _ from "lodash";
import moment from "moment";
import { getAllPermitTermOptionsByLots } from "../../Actions/PermitOptionsActions/actions";

const AddPermitOptionsModal = (props) => {
  const dispatch = useDispatch();
  const property_id = props.property_id;
  const [formData, setFormData] = useState({
    lot_id: "",
    stall_type: "no_stall",
    max_amount: "",
    cost: "",
    usage_count: "",
    expire_in: "",
    one_usage_per_day: false,
    allow_waitlist: false,
    daily_amount: "",
    max_permit_count: "",
    sell_to_units: false,
    sell_to_drivers: false,
    description: "",
    package_name: "",
    allLotsList: [],
    allStallTypeList: [
      { id: 1, text: "No Stall Management", value: "no_stall" },
      { id: 2, text: "Manual", value: "manual" },
      { id: 3, text: "Assigned", value: "assigned" },
      { id: 4, text: "User Selected", value: "user_selected" },
    ],
    termOptionsList: [],
    show_after:"",
    is_form_valid: "",
    errors: {},
    show_alert: false,
    show_error: false,
  });
  const [selectedOption, setselectedOption] = React.useState("monthly_permits");
  const [selectedExpireDateOption, setExpireDateselectedOption] = React.useState("expire_in_days");

  const allLots = useSelector((state) => state.SuperAdminDetails.lots);
  const allTerms = useSelector((state) => state.PermitOptionsDetails.termOptionsList);

  
  useEffect(() => {
    dispatch(get_all_lots(property_id, "", false, true));
    setFormData((prevState) => ({
      ...prevState,
      property_id: property_id,
    }));
  }, []);
  
  useEffect(() => {
    if(formData.end_time){
      let formatedEndDate = moment(formData.end_time).format("YYYY-MM-DD");
      dispatch(getAllPermitTermOptionsByLots(property_id, formData.lot_id, formatedEndDate));
      setFormData((prevState) => ({
        ...prevState,
        property_id: property_id,
      }));
    }
  }, []);

  useEffect(() => {
    if (props.ifEdit) {
      if (props.selectedRowData && Object.keys(props.selectedRowData).length) {
        const {
          lot_id,
          max_amount,
          cost,
          usage_count,
          daily_amount,
          max_permit_count,
          sell_to_units,
          sell_to_drivers,
          description,
          package_name,
          start_time,
          end_time,
          renew_to,
          termOptionsList,
          show_after,
          permit_type,
          expire_in_days,
          one_usage_per_day,
          allow_waitlist,
          stall_type,
        } = props.selectedRowData;
        let sell_to_units_data = true;
        let sell_to_drivers_data = true;
        const expire_in = expire_in_days;
        let count_one_usage_per_day = true;
        let allow_waitlist_data = true;
        if (sell_to_units === 0) {
          sell_to_units_data = false;
        }
        if (sell_to_drivers === 0) {
          sell_to_drivers_data = false;
        }
        if (one_usage_per_day === 0) {
          count_one_usage_per_day = false;
        }
        if (allow_waitlist === 0) {
          allow_waitlist_data = false;
        }
        if (expire_in === "end_of_month") {
          setExpireDateselectedOption(expire_in);
        } else {
          setExpireDateselectedOption("expire_in_days");
        }
        setselectedOption(permit_type);
       
        setFormData((prevState) => ({
          ...prevState,
          lot_id: lot_id,
          package_name: package_name,
          max_amount: max_amount,
          cost: cost,
          usage_count: usage_count,
          daily_amount: daily_amount,
          expire_in: expire_in,
          max_permit_count: max_permit_count,
          start_time: start_time,
          end_time: end_time,
          show_after:show_after,
          termOptionsList:termOptionsList,
          renew_to:renew_to,
          sell_to_units: sell_to_units_data,
          sell_to_drivers: sell_to_drivers_data,
          description: description,
          one_usage_per_day: count_one_usage_per_day,
          allow_waitlist: allow_waitlist_data,
          old_data: props.selectedRowData,
          stall_type: stall_type,
        }));
      }
    }
  }, [props.ifEdit]);

    const handleChangeStartingDates = (date) => {
     const newDate = date !== null ? date : this.start_time;
     date = moment(newDate).format("L");
     formData.start_time = date;
    setFormData((prevState) => ({
      ...prevState,
      start_time: date,
    }));
   };

  
  const handleChangeShowAfterDates = (date) => {
     const newDate = date !== null ? date : this.state.show_after;     
     date = moment(newDate).format("L");
     formData.show_after = date;

    setFormData((prevState) => ({
      ...prevState,
      show_after: date,
    }));
  };

   const handleChangeEndingDates = (date) => {
     const newDate = date !== null ? date : this.state.end_time;
     date = moment(newDate).format("L");
     formData.end_time = date;
     let formatedEndDate = moment(date).format("YYYY-MM-DD");
     if(formData.lot_id){
         dispatch(getAllPermitTermOptionsByLots(property_id, formData.lot_id, formatedEndDate));
     }
     setFormData((prevState) => ({
       ...prevState,
       end_time: date,
       termOptionsList: allTerms,
     }));
   };

    useEffect(() => {
      if (allTerms?.length) {
        setFormData((prevState) => ({
          ...prevState,
          termOptionsList: allTerms,
        }));
      }
    }, [allTerms]);



  useEffect(() => {
    if (allLots?.length) {
      setFormData((prevState) => ({
        ...prevState,
        allLotsList: allLots,
      }));
    }
  }, [allLots]);

  const handleChange = ({ target }) => {
    let formValue;
    if (target.type === "checkbox") {
      const value = !formData[target.name];
      setFormData((prevState) => ({
        ...prevState,
        [target.name]: value,
      }));
    } else {
      const regex = /^[1-9]\d*$/;
      if (regex.test(target.value) === false && ["usage_count", "expire_in", "max_permit_count"].includes(target.name)) {
        formValue = "";
      } else {
        formValue = target.value;
      }

      setFormData((prevState) => ({
        ...prevState,
        [target.name]: formValue,
      }));
    }
    checkValidationsOnChange(target);
  };


  const handleTermPermitChange = (event) => {
    const selectedPermitTermId = event.target.value;
    setFormData({
      ...formData,
      renew_to: selectedPermitTermId,
    });

  };

  const checkValidationsOnChange = useCallback(
    (target) => {
      let is_form_valid = true;
      const errors = {};
      console.log("YYYYYYYY", target?.value?.toString());
      if (!target?.value?.toString()?.length) {
        is_form_valid = false;
        errors[target.name] = `${_.startCase(target.name).split("_").join(" ")} can't be empty`;
      } else {
        is_form_valid = true;
        errors[target.name] = "";
      }

      if (target.name === "sell_to_units") {
        if (formData[target.name] && !formData.sell_to_drivers) {
          is_form_valid = false;
          errors.sell = `Sell to units or driver must be selected`;
        } else {
          is_form_valid = true;
          errors.sell = "";
        }
      }
      if (target.name === "sell_to_drivers") {
        if (formData[target.name] && !formData.sell_to_units) {
          is_form_valid = false;
          errors.sell = `Sell to units or driver must be selected`;
        } else {
          is_form_valid = true;
          errors.sell = "";
        }
      }

      if (target.name === "sell_to_drivers" && selectedOption === "usage_permits") {
        if (formData[target.name] && !formData.sell_to_drivers) {
          is_form_valid = false;
          errors.sell = `Sell to  driver must be selected`;
        } else {
          is_form_valid = true;
          errors.sell = "";
        }
      }
      if (target.name === "lot_id") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          errors.lot_id = `${target.name} can't be empty`;
        } else {
          is_form_valid = true;
          errors.lot_id = "";
        }
      }
      setFormData((prevState) => ({
        ...prevState,
        is_form_valid: is_form_valid,
        errors: { ...prevState.errors, ...errors },
      }));
      return is_form_valid;
    },
    [formData],
  );

  const handleChangeLot = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      lot_id: e.target.value,
    }));
    checkValidationsOnChange(e.target);
  };

  const handleChangeStallType = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      stall_type: e.target.value,
    }));
  };

  const checkValidations = () => {
    let is_form_valid = true;
    const errors = {};
    const { lot_id, max_amount, cost, usage_count, expire_in, daily_amount, max_permit_count, description, sell_to_units, sell_to_drivers, package_name } =
      formData;
    if (!lot_id?.trim().length) {
      is_form_valid = false;
      errors.lot_id = `Lot can't be empty`;
    }
    if (!max_amount?.toString().length && selectedOption === "monthly_permits") {
      is_form_valid = false;
      errors.max_amount = `Monthly max rate can't be empty`;
    }
    if (!cost?.toString().length && selectedOption === "usage_permits") {
      is_form_valid = false;
      errors.cost = `Cost can't be empty`;
    }

    if (!expire_in?.toString().length && selectedOption === "usage_permits" && selectedExpireDateOption === "expire_in_days") {
      is_form_valid = false;
      errors.expire_in = `Day's can't be empty`;
    }

    if (!usage_count?.toString().length && selectedOption === "usage_permits") {
      is_form_valid = false;
      errors.usage_count = `Usage can't be empty`;
    }

    if (!daily_amount?.toString().length && selectedOption === "monthly_permits") {
      is_form_valid = false;
      errors.daily_amount = `Daily rate can't be empty`;
    }
    console.log("max_permit_count:11111", max_permit_count?.toString().length, max_permit_count);
    if (!max_permit_count.toString().length) {
      is_form_valid = false;
      errors.max_permit_count = `Max count to issue can't be empty`;
    }
    if (!description?.trim().length) {
      is_form_valid = false;
      errors.description = `Description can't be empty`;
    }
    if (!package_name?.trim().length) {
      is_form_valid = false;
      errors.package_name = `Package name can't be empty`;
    }
    if (!sell_to_units && !sell_to_drivers && selectedOption === "monthly_permits") {
      is_form_valid = false;
      errors.sell = `Sell to units or driver  must be selected`;
    }

    if (!sell_to_drivers && selectedOption === "usage_permits" && selectedExpireDateOption === "expire_in_days") {
      is_form_valid = false;
      errors.sell = `Sell to driver must be selected`;
    }

    if (!sell_to_drivers && !sell_to_units && selectedOption === "usage_permits" && selectedExpireDateOption === "end_of_month") {
      is_form_valid = false;
      errors.sell = `Sell to units or driver  must be selected`;
    }

    setFormData((prevState) => ({
      ...prevState,
      is_form_valid: is_form_valid,
      errors: errors,
    }));
    return is_form_valid;
  };

  const showError = (type) => {
    let valid = true;
    let errorMsg = "";
    if (type === "lot_id" && formData.errors?.lot_id) {
      valid = false;
      errorMsg = formData.errors.lot_id;
    }
    if (type === "max_amount" && formData.errors?.max_amount) {
      valid = false;
      errorMsg = formData.errors.max_amount;
    }
    if (type === "cost" && formData.errors?.cost) {
      valid = false;
      errorMsg = formData.errors.cost;
    }
    if (type === "usage_count" && formData.errors?.usage_count) {
      valid = false;
      errorMsg = formData.errors.usage_count;
    }

    if (type === "expire_in" && formData.errors?.expire_in) {
      valid = false;
      errorMsg = formData.errors.expire_in;
    }

    if (type === "daily_amount" && formData.errors?.daily_amount) {
      valid = false;
      errorMsg = formData.errors.daily_amount;
    }
    if (type === "max_permit_count" && formData.errors?.max_permit_count) {
      valid = false;
      errorMsg = formData.errors.max_permit_count;
    }
    if (type === "description" && formData.errors?.description) {
      valid = false;
      errorMsg = formData.errors.description;
    }
    if (type === "package_name" && formData.errors?.package_name) {
      valid = false;
      errorMsg = formData.errors.package_name;
    }
    if (type === "sell" && formData.errors?.sell) {
      valid = false;
      errorMsg = formData.errors.sell;
    }

    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }
    return true;
  };

  const handleAddPermitOptions = async () => {
    const {
      lot_id,
      max_amount,
      cost,
      usage_count,
      daily_amount,
      max_permit_count,
      sell_to_units,
      sell_to_drivers,
      description,
      package_name,
      expire_in,
      one_usage_per_day,
      old_data,
      allow_waitlist,
      stall_type,
    } = formData;

    if (checkValidations()) {
      const data = {
        lot_id: lot_id,
        max_amount: max_amount || 0.0,
        daily_amount: daily_amount || 0.0,
        max_permit_count: max_permit_count,
        sell_to_units: sell_to_units,
        sell_to_drivers: sell_to_drivers,
        description: description,
        package_name: package_name.trim(),
        property_id: property_id,
        permit_type: selectedOption,
        expire_in_days: expire_in || null,
        cost: cost || 0,
        usage_count: usage_count || null,
        one_usage_per_day: one_usage_per_day || false,
        allow_waitlist: allow_waitlist || false,
        stall_type: stall_type,
        old_data: old_data || null,
      };

      if (props.ifEdit) {
        props.addUpdatePermitOptions(data, props.selectedRowId);
      } else {
        props.addUpdatePermitOptions(data);
      }
    }
  };

  const handlePackageChange = (e) => {
    setselectedOption(e.target.value);

    setFormData({
      ...formData,
      daily_amount: "",
      max_amount: "",
      cost: "",
      usage_count: "",
      sell_to_units: false,
      sell_to_drivers: false,
      expire_in: "",
    });
    checkValidationsOnChange(e.target);
  };

  const handleExpireDateChange = (e) => {
    setExpireDateselectedOption(e.target.value);
    if (e.target.name === "end_of_month") {
      setFormData({
        ...formData,
        expire_in: "end_of_month",
      });
    } else {
      setFormData({
        ...formData,
        expire_in: "",
        sell_to_units: false,
      });
    }
    checkValidationsOnChange(e.target);
  };

  const blockInvalidChar = (e) => {
    return ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const blockDotInvalidChar = (e) => {
    return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  };

  return (
    <div>
      <Modal lg={"medium"} show={props.show} onHide={props.closeModal} dialogClassName="modal-90w" fullscreen={"sm-down"}>
        <Card.Header className="d-flex justify-content-between bg-white align-items-center">
          <h4 className="m-0 font-weight-bold">
            <Card.Title as="h4" className="m-0">
              {props.ifEdit ? "Edit" : "Add"} Permit Packages
            </Card.Title>
          </h4>
          <Button title={"Close"} type="button" onClick={props.closeModal} className="close-btn py-0 pr-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger"></i>
          </Button>
        </Card.Header>
        <Modal.Body>
          <Form.Group>
            <Row>
              <Col sm="12" lg="6">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="mb-0">Lots:</Form.Label>
                  <span className="asterisk">*</span>
                  <Form.Control as="select" name="lot_id" value={formData.lot_id} onChange={handleChangeLot} placeholder="Select Lot" disabled={props.ifEdit}>
                    {formData?.allLotsList?.length ? (
                      <>
                        <option value="">Select lot</option>
                        {formData.allLotsList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.alias}
                          </option>
                        ))}
                      </>
                    ) : null}
                  </Form.Control>
                  {showError("lot_id")}
                </Form.Group>
              </Col>
              <Col sm="12" lg="6">
                <div className="form-group">
                  <label className="mb-0">
                    Package Name
                    <span className="asterisk">*</span>
                  </label>
                  <Form.Control
                    placeholder="Name"
                    type="text"
                    name="package_name"
                    value={formData.package_name}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    maxlength="80"
                  />
                  {showError("package_name")}
                </div>
              </Col>
            </Row>
            <Row>
              <div className="w-100 ">
                <Form.Group className="ml-3 mt-2 mb-2 mr-3 d-flex flex-wrap">
                  <div className="d-flex mb-1">
                    <Form.Check
                      inline
                      label="Monthly Permits"
                      name="monthly_permits"
                      type="radio"
                      value="monthly_permits"
                      id="1"
                      onChange={(e) => {
                        handlePackageChange(e);
                      }}
                      checked={selectedOption === "monthly_permits"}
                      disabled={props.ifEdit}
                    />
                  </div>
                  <div className="d-flex mb-1">
                    <Form.Check
                      inline
                      label="Usage Permits"
                      name="usage_permits"
                      type="radio"
                      value="usage_permits"
                      id="2"
                      onChange={(e) => {
                        handlePackageChange(e);
                      }}
                      checked={selectedOption === "usage_permits"}
                      disabled={props.ifEdit}
                    />
                  </div>
                </Form.Group>
              </div>
            </Row>
            {selectedOption === "monthly_permits" && (
              <Row>
                <Col sm="12" lg="6">
                  <div className="form-group">
                    <label className="mb-0">
                      Monthly Max Rate ($) <span className="asterisk">*</span>
                    </label>
                    <Form.Control
                      placeholder="Month Max Amount"
                      name="max_amount"
                      type="number"
                      value={formData.max_amount}
                      onChange={handleChange}
                      onKeyDown={blockInvalidChar}
                      min={0}
                      autoComplete="off"
                      required
                    />
                    {showError("max_amount")}
                  </div>
                </Col>
                <Col sm="12" lg="6">
                  <div className="form-group">
                    <label className="mb-0">
                      Daily Rate ($) <span className="asterisk">*</span>
                    </label>
                    <Form.Control
                      placeholder="Daily Amount"
                      type="number"
                      name="daily_amount"
                      value={formData.daily_amount}
                      onChange={handleChange}
                      onKeyDown={blockInvalidChar}
                      min={1}
                      autoComplete="off"
                      required
                    />
                    {showError("daily_amount")}
                  </div>
                </Col>
              </Row>
            )}

            {selectedOption === "usage_permits" && (
              <>
                {" "}
                <Row>
                  <Col sm="12" lg="6">
                    <div className="form-group">
                      <label className="mb-0">
                        Cost ($)
                        <span className="asterisk">*</span>
                      </label>
                      <Form.Control
                        placeholder="Cost"
                        name="cost"
                        type="number"
                        value={formData.cost}
                        onChange={handleChange}
                        onKeyDown={blockInvalidChar}
                        min={0}
                        autoComplete="off"
                        required
                      />
                      {showError("cost")}
                    </div>
                  </Col>
                  <Col sm="12" lg="6">
                    <div className="form-group">
                      <label className="mb-0">
                        Usage
                        <span className="asterisk">*</span>
                      </label>
                      <Form.Control
                        placeholder="Usage"
                        type="text"
                        name="usage_count"
                        value={formData.usage_count}
                        onChange={handleChange}
                        onKeyDown={blockInvalidChar}
                        min={1}
                        maxLength="3"
                        autoComplete="off"
                        required
                      />
                      {showError("usage_count")}
                    </div>
                  </Col>
                  <Col sm="12" lg="12">
                    <label className="mb-0">
                      Expire In
                      <span className="asterisk">*</span>
                    </label>
                    <Row>
                      <Col sm="6" lg="6">
                        <div className="d-flex mb-1">
                          <Form.Check
                            inline
                            label="Expire In Days"
                            name="expire_in_days"
                            type="radio"
                            value="expire_in_days"
                            id="3"
                            onChange={(e) => {
                              handleExpireDateChange(e);
                            }}
                            checked={selectedExpireDateOption === "expire_in_days"}
                            disabled={props.ifEdit}
                          />
                        </div>
                      </Col>
                      <Col sm="6" lg="6">
                        {selectedExpireDateOption === "expire_in_days" && (
                          // <Col sm="12" lg="6">
                          <div className="form-group">
                            <Form.Control
                              placeholder="Expire in days"
                              type="text"
                              name="expire_in"
                              value={formData.expire_in}
                              onChange={handleChange}
                              onKeyDown={blockInvalidChar}
                              min={1}
                              maxLength="3"
                              autoComplete="off"
                              required
                            />
                            {showError("expire_in")}
                          </div>
                          // </Col>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    {/* <Form.Group className="ml-3 mt-2 mb-2 mr-3 d-flex flex-wrap"> */}

                    <div className="d-flex mb-1">
                      <Form.Check
                        inline
                        label="End of Month"
                        name="end_of_month"
                        type="radio"
                        value="end_of_month"
                        id="4"
                        onChange={(e) => {
                          handleExpireDateChange(e);
                        }}
                        checked={selectedExpireDateOption === "end_of_month"}
                        disabled={props.ifEdit}
                      />
                    </div>
                    {/* </Form.Group> */}
                  </Col>

                  <Col sm="12" lg="12">
                    <label className="fancy-checkbox">
                      <input
                        type="checkbox"
                        name={"one_usage_per_day"}
                        className="custom-control-input"
                        id="one_usage_per_day"
                        onChange={handleChange}
                        checked={formData.one_usage_per_day}
                      ></input>
                      <span>
                        <i></i> Count one usage per calendar day
                      </span>
                    </label>
                  </Col>
                </Row>{" "}
              </>
            )}
            {/*Term Permits Condition*/}
            {selectedOption === "term_permits" && (
              <>
                {" "}
                <Row>
                  <Col sm="12" lg="6">
                    <div className="form-group">
                      <div className="mb-3">
                        <label className="mb-0">Start Date:</label>
                        <DatePicker
                          placeholder="04/05/16"
                          value={formData.start_time}
                          selected={formData.start_time ? new Date(formData.start_time) : new Date()}
                          onChange={handleChangeStartingDates}
                          minDate={new Date(formData.start_time)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" lg="6">
                    <div className="form-group">
                      <div className="mb-3">
                        <label className="mb-0">End Date:</label>
                        <DatePicker
                          placeholder="04/05/16"
                          value={formData.end_time}
                          selected={formData.end_time ? new Date(formData.end_time) : new Date()}
                          onChange={handleChangeEndingDates}
                          minDate={new Date(formData.end_time)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" lg="6">
                    <div className="form-group">
                      <label className="mb-0">
                        Cost ($)
                        <span className="asterisk">*</span>
                      </label>
                      <Form.Control
                        placeholder="Cost"
                        name="cost"
                        type="number"
                        value={formData.cost}
                        onChange={handleChange}
                        onKeyDown={blockInvalidChar}
                        min={0}
                        autoComplete="off"
                        required
                      />
                      {showError("cost")}
                    </div>
                  </Col>
                  <Col sm="12" lg="6">
                    <div className="form-group">
                      <label className="mb-0">Available From:</label>
                      <DatePicker
                        placeholder="04/05/16"
                        value={formData.show_after}
                        selected={formData.show_after ? new Date(formData.show_after) : new Date()}
                        onChange={handleChangeShowAfterDates}
                        minDate={getShowDate(formData.start_time)}
                        maxDate={new Date(formData.start_time)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label htmlFor="permitDropdown" className="fancy-checkbox">
                      Auto Renew TO:{" "}
                    </label>{" "}
                    <Form.Control as="select" id="permitDropdown" onChange={handleTermPermitChange}>
                      <option value="">--Choose Auto Renew To--</option>
                      {formData?.termOptionsList?.length ? (
                        <>
                          {formData.termOptionsList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.package_name}
                            </option>
                          ))}
                        </>
                      ) : null}
                    </Form.Control>
                  </Col>
                </Row>{" "}
              </>
            )}

            <Row>
              <Col sm="12" lg="12">
                <div className="form-group">
                  <label className="mb-0">
                    Description
                    <span className="asterisk">*</span>
                  </label>
                  <Form.Control
                    placeholder="Description"
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    maxlength="80"
                  />
                  {showError("description")}
                </div>
              </Col>
            </Row>
            <Row>
              {selectedOption === "usage_permits" && selectedExpireDateOption === "expire_in_days" ? (
                <></>
              ) : (
                <Col sm="12" lg="4">
                  <label className="fancy-checkbox">
                    <input
                      type="checkbox"
                      name={"sell_to_units"}
                      className="custom-control-input"
                      id="sell_to_units"
                      onChange={handleChange}
                      checked={formData.sell_to_units}
                    ></input>
                    <span>
                      <i></i> Sell To Units
                    </span>
                  </label>
                </Col>
              )}

              <Col sm="12" lg="8">
                <div className="form-group mb-0">
                  <label className="fancy-checkbox">
                    <input
                      type="checkbox"
                      name={"sell_to_drivers"}
                      className="custom-control-input"
                      id="sell_to_drivers"
                      onChange={handleChange}
                      checked={formData.sell_to_drivers}
                    ></input>
                    <span>
                      <i></i> Sell To Drivers
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm="12">{showError("sell")} </Col>
            </Row>
            <Row>
              {selectedOption === "monthly_permits" ? (
                <Col sm="12" lg="4 " className="mt-lg-4">
                  <label className="fancy-checkbox mb-0">
                    <input
                      type="checkbox"
                      name={"allow_waitlist"}
                      className="custom-control-input"
                      id="allow_waitlist"
                      onChange={handleChange}
                      checked={formData.allow_waitlist}
                    ></input>
                    <span>
                      <i></i> Allow Waitlist
                    </span>
                  </label>
                </Col>
              ) : (
                <Col sm="12" lg="4"></Col>
              )}

              <Col sm="12" lg="8">
                <div className="form-group">
                  <label className="mb-0">
                    Max Count to issue on Parkingpermit portal <span className="asterisk">*</span>
                  </label>
                  <Form.Control
                    placeholder="Max Count To Issue"
                    type="number"
                    name="max_permit_count"
                    value={formData.max_permit_count}
                    onChange={handleChange}
                    onKeyDown={blockDotInvalidChar}
                    min={1}
                    autoComplete="off"
                    required
                  />
                  {showError("max_permit_count")}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="mb-0">Stall Type:</Form.Label>
                  {/* <span className="asterisk">*</span> */}
                  <Form.Control as="select" name="stall_type" value={formData.stall_type} onChange={handleChangeStallType} placeholder="Select Stall Type">
                    {formData?.allStallTypeList?.length ? (
                      <>
                        {formData.allStallTypeList.map((item) => (
                          <option key={item.id} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </>
                    ) : null}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button className="btn-fill" type="button" variant="info" onClick={handleAddPermitOptions}>
            {props.ifEdit ? "Update" : "Add"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AddPermitOptionsModal;
